import { useMemo, useState, useCallback, useRef } from 'react'
import CreditSpendButton from '@/components/credit-spend-button'
import IconEdit from '@haiper/icons-svg/icons/outline/edit.svg'
import PaintBrush from '@haiper/icons-svg/icons/outline/brush.svg'
import IconRefresh from '@haiper/icons-svg/icons/outline/refresh-ccw.svg'
import Button from '@/components/button'
import Dialog from '@/components/dialog'
import { formatSpu, UseCreationActionsResult } from '@/hooks/useCreationActions'
import ShareDialog from './share-dialog'
import { toast } from '@/components/ui/use-toast'
import IconHeartOutline from '@haiper/icons-svg/icons/outline/heart.svg'
import IconHeartSolid from '@haiper/icons-svg/icons/solid/heart.svg'
import IconChat from '@haiper/icons-svg/icons/outline/chat.svg'
import IconDownload from '@haiper/icons-svg/icons/outline/download.svg'
import IconShare from '@haiper/icons-svg/icons/outline/share.svg'
import VisibilityDialog from '@/app/creations/_visibility-dialog'
import IconCheck from '@haiper/icons-svg/icons/outline/check-large.svg'
import IconFolderOutline from '@haiper/icons-svg/icons/outline/folder-add.svg'
import IconFolderSolid from '@haiper/icons-svg/icons/solid/folder.svg'
import IconLoading from '@/public/assets/loading.svg'
import { getShareOrigin, cls, stopPropagation, isMobile, whisper, getCreationDetailUrl, isLocalDev } from '@/utils'
import UpgradeButton from '@/components/upgrade-button'
import useActivePlan from '@/hooks/useActivePlan'
import LoadingButton from '../loading-button'
import Link from '@/components/link'
import { useBreakpoint } from '@/hooks/useBreakPoint'
import IconClockAdd from '@haiper/icons-svg/icons/outline/clock-add.svg'
import IconFeatures from '@haiper/icons-svg/icons/outline/features.svg'
import useCredit from '@/hooks/useCredit'
import useAmplitude from '@/hooks/useAmplitude'
import { PoNVoid, GalleryItemSource, TemplateGenerationParams, CreationBase, ModelVersion } from '@/types'
import { Textarea } from '../ui/textarea'
import ButtonGroup, { ButtonGroupItem, ButtonGroupProps } from '../button-group'
import SelectCollectionDialog, { SelectCollectionDialogRef } from '@/components/select-collection-dialog'
import { getCollectionsOfCreation } from '@/service/collection.service'
import IconMultiMedia from '@haiper/icons-svg/icons/outline/multi-media.svg'
import IconShareScreen from '@haiper/icons-svg/icons/outline/share-screen.svg'

export type ControlVisibleState = boolean | 'fold'

// make all controls visible if controls not specified
export const defaultControls: InteractionsProps['controls'] = {
  showRegenerate: false,
  showDelete: false,
  showVaryPrompt: false,
  showRepaint: false,
  showExtend: false,
  showUpscale: false,
  showFavorite: true,
  showFavoriteCount: true,
  showDownload: true,
  showVisibility: false,
  showShare: true,
  showSubmit: true,
}

export interface InteractionsProps extends Pick<ButtonGroupProps, 'size'> {
  className?: string
  actions: UseCreationActionsResult
  itemClassName?: string
  iconClassName?: string
  singleColumn?: boolean
  disabled?: boolean
  useButtonGroup?: boolean
  outputType?: string
  onClick?: (e?: any) => PoNVoid
  source: GalleryItemSource
  hidden?: boolean
  controls?: {
    showRegenerate?: ControlVisibleState
    showVaryPrompt?: ControlVisibleState
    showRepaint?: ControlVisibleState
    showExtend?: ControlVisibleState
    showUpscale?: ControlVisibleState
    showImage2Video?: ControlVisibleState
    showFavorite?: ControlVisibleState
    showFavoriteCount?: boolean
    showDownload?: ControlVisibleState
    showDelete?: ControlVisibleState
    showSubmit?: ControlVisibleState
    showVisibility?: ControlVisibleState
    showShare?: ControlVisibleState
    showCollection?: ControlVisibleState
    showComment?: ControlVisibleState
  }
}

export default function Interactions({
  className,
  itemClassName,
  iconClassName,
  disabled,
  singleColumn,
  controls: _controls = defaultControls,
  useButtonGroup = false,
  size,
  outputType,
  onClick,
  actions,
  source,
  hidden,
}: InteractionsProps) {
  const {
    handleDownload,
    handleImageDownload,
    handleExtend,
    handleUpscale,
    handleDelete,
    handleRepaint,
    handleFavorite,
    handleAddCollection,
    handleImage2Video,
    handleOpenComment,
    refreshJobs,
    setIsPublic,
    collectsCount,
    isPublic,
    favorited,
    hasAddCollection,
    isAuthor,
    creationId,
    outputId,
    creation,
    base,
    output,
    trackEventParams,
    realDuration,
    nextExtendDuration,
    extendDisableMessage,
    upscaleDisableMessage,
    repaintDisableMessage,
    handleSubmit,
    handleRegenerate: handleRegenerateAction,
    handleVaryPrompt: handleVaryPromptAction,
  } = actions

  const extendedDuration = creation?.config?.extend_duration ?? 0

  const { data: activePlan } = useActivePlan()
  const canDownloadWatermarkFree = !!activePlan?.allow_watermark_free_download

  const { refreshCredit } = useCredit()

  const varyPromptRef = useRef<HTMLTextAreaElement>(null)

  const [showRegenerateDialog, setShowRegenerateDialog] = useState(false)
  const [showExtendDialog, setShowExtendDialog] = useState(false)
  const [showUpscaleDialog, setShowUpscaleDialog] = useState(false)
  const [showVaryPromptDialog, setShowVaryPromptDialog] = useState(false)
  const [showDeleteDialog, setShowDeleteDialog] = useState(false)
  const [showShareDialog, setShowShareDialog] = useState(false)
  const [showVisibilityDialog, setShowVisibilityDialog] = useState(false)
  const [selectedKeys, setSelectedKeys] = useState<string[]>([])
  const [collectionKeysLoading, setCollectionKeysLoading] = useState(false)
  const selectCollectionDialogRef = useRef<SelectCollectionDialogRef>(null)

  const isFreePlan = activePlan?.is_free || !activePlan
  const { track } = useAmplitude()

  const handleVaryPrompt = useCallback(async () => {
    const newPrompt = varyPromptRef.current?.value
    if (!newPrompt) {
      toast({ title: 'Please input a new prompt' })
      return
    }
    track('click:creation:vary-prompt-confirm', {
      creation_id: creationId,
    })
    await handleVaryPromptAction(newPrompt)
    if (refreshCredit) {
      await refreshCredit()
    }
    setShowVaryPromptDialog(false)
    refreshJobs()
  }, [creationId, refreshJobs, refreshCredit, track, handleVaryPromptAction])

  const handleRegenerate = useCallback(async () => {
    track('click:creation:regenerate-confirm', {
      creation_id: creationId,
    })

    await handleRegenerateAction()

    if (refreshCredit) {
      await refreshCredit()
    }
    setShowRegenerateDialog(false)
    refreshJobs()
  }, [creationId, refreshJobs, refreshCredit, track, handleRegenerateAction])

  const innerHandleDownload = useCallback(
    async (watermarkFree: boolean) => {
      setDownloading(true)
      try {
        const success = await handleDownload(watermarkFree)
        setDownloading(false)
        setDownloadSuccess(success)
        if (downloadSuccessTimeoutIdRef.current) {
          clearTimeout(downloadSuccessTimeoutIdRef.current)
        }
        if (success) {
          downloadSuccessTimeoutIdRef.current = setTimeout(() => {
            setDownloadSuccess(false)
          }, 3 * 1000)
        }
      } catch (error) {
        console.error(error)
        setDownloading(false)
      }
    },
    [handleDownload],
  )

  const [downloading, setDownloading] = useState(false)
  const [downloadSuccess, setDownloadSuccess] = useState(false)

  const { isBelowMd } = useBreakpoint('md')

  const hasWatermarkFreeUrl = !!base?.is_watermark_free_enabled
  const disableDefaultDownloadButton = hasWatermarkFreeUrl && isAuthor && !isBelowMd

  const hasOutput = creation?.status === 'succeed' || source !== 'creations'
  const isModel2 = creation?.model_version === ModelVersion.TWO

  const isImage = base?.output_type === 'image'

  const downloadTooltipClassname =
    !isAuthor || isImage || !hasOutput
      ? ''
      : cls('p-1 rounded-lg border border-border border-solid bg-surface', hasWatermarkFreeUrl ? 'w-40' : 'w-50')

  const collectionTooltip = useMemo(() => {
    if (!isFreePlan) return 'Collection'
    return (
      <div className='text-text w-full text-center text-body-sm leading-4 z-100'>
        <div>Upgrade to</div>
        <Link className='text-surface-primary' href='/membership'>
          Haiper Membership
        </Link>
        <div>to create collections</div>
        <UpgradeButton className='w-full mt-4 h-7' source='download-watermark-free' />
      </div>
    )
  }, [isFreePlan])

  const collectionTooltipClassname =
    'w-[170px] border border-border hover:border-border-hover p-4 bg-surface hover:bg-surface-hover active:bg-surface-hover'

  const downloadTooltip = useMemo(() => {
    if (!isAuthor || isImage || !hasOutput) {
      return 'Download'
    }
    if (!hasWatermarkFreeUrl) {
      return (
        <div
          className='flex flex-col gap-4 text-text w-full p-4 text-body-sm leading-4 z-100'
          onClick={stopPropagation as any}
        >
          <span className='leading-4 text-center'>
            Videos created on
            <Link
              href='/membership'
              className='text-text-interactive font-bold px-1 hover:no-underline hover:text-text-interactive-hover whitespace-nowrap'
            >
              Pro Plan
            </Link>
            <br />
            can be downloaded without watermark
          </span>
          {canDownloadWatermarkFree ? null : <UpgradeButton className='h-7' source='download-watermark-free' />}
        </div>
      )
    }

    const downloadButtonClassname = cls(
      'w-full h-10 border-0 border-solid border-border first-of-type:rounded-t-md last-of-type:rounded-b-md px-3 py-2 flex justify-start items-center hover:bg-surface-hover active:bg-surface-hover',
    )
    return (
      <div className=''>
        <LoadingButton
          variant='transparent'
          className={downloadButtonClassname}
          onClick={() => innerHandleDownload(false)}
        >
          <div className='w-full text-center'>With watermark</div>
        </LoadingButton>
        <LoadingButton
          variant='transparent'
          className={downloadButtonClassname}
          onClick={() => innerHandleDownload(true)}
        >
          <div className='w-full text-center'>Without watermark</div>
        </LoadingButton>
      </div>
    )
  }, [canDownloadWatermarkFree, innerHandleDownload, hasWatermarkFreeUrl, isAuthor, isImage, hasOutput])

  const groupItemStyle = useButtonGroup ? 'bg-surface hover:bg-surface-hover active:bg-surface-hover ' : ''

  const controls = useMemo(() => {
    const blackListControls = isAuthor
      ? {}
      : {
          showDelete: false,
          showVisibility: false,
        }
    return {
      ..._controls,
      ...blackListControls,
    }
  }, [_controls, isAuthor])

  const innerHandleDelete = useCallback(async () => {
    await handleDelete()
    toast({
      title: 'Video has been deleted',
      color: 'success',
    })
  }, [handleDelete])

  const disabledItemClassname = cls('text-icon-subdued dark:text-icon-subdued opacity-50 dark:opacity-50')
  const parsedIconClassName = cls('size-5 text-icon inline-block', iconClassName, disabled ? disabledItemClassname : '')

  const shareLink = useMemo(() => {
    const host = getShareOrigin()
    const pathname = getCreationDetailUrl(base as CreationBase)
    return `${host}${pathname}`
  }, [base])

  const baseItemClassName = cls('p-0')

  const buttonParsedClassName = cls(
    baseItemClassName,
    useButtonGroup ? '' : '',
    itemClassName,
    disabled ? disabledItemClassname : '',
  )

  const iconButtonParsedClassName = cls(buttonParsedClassName, itemClassName)

  const IconFolder = hasAddCollection ? IconFolderSolid : IconFolderOutline

  const downloadSuccessTimeoutIdRef = useRef<NodeJS.Timeout | null>(null)

  const DownloadIcon =
    downloadSuccess && !disableDefaultDownloadButton
      ? IconCheck
      : downloading && !disableDefaultDownloadButton
        ? IconLoading
        : IconDownload

  const disableImage2Video = disabled || !isImage

  const extendPromptTextareaRef = useRef<HTMLTextAreaElement>(null)

  const isTemplateGeneration =
    creation?.type === 'template' && !!(creation.config as TemplateGenerationParams['config'])?.template_id

  const disableExtend = disabled || !!extendDisableMessage || !hasOutput || isTemplateGeneration
  const disableUpscale = disabled || !!upscaleDisableMessage || !hasOutput || isTemplateGeneration
  const disableModifyButtons = disabled || (!hasOutput && !isImage) || base?.is_illegal

  const hasPromptTemplateInput =
    (isTemplateGeneration || creation?.type === 'template') &&
    !!(creation.config as TemplateGenerationParams['config'])?.template_inputs?.prompt

  const disableVaryPrompt =
    disableModifyButtons || ((isTemplateGeneration || creation?.type === 'template') && !hasPromptTemplateInput)

  const handlePickCollection = async () => {
    setCollectionKeysLoading(true)
    await selectCollectionDialogRef.current?.open()
    const data = await getCollectionsOfCreation(creation?.creation_id || '')
    setSelectedKeys(data)
    setCollectionKeysLoading(false)
  }

  const leftButtons: ButtonGroupItem[] = [
    {
      key: 'regenerate',
      icon: IconRefresh,
      disabled: disableModifyButtons,
      visible: !!controls?.showRegenerate,
      tooltip: {
        title: 'Regenerate',
        content: '',
        className: '',
        align: 'start',
      },
      onClick: () => setShowRegenerateDialog(true),
    },
    {
      key: 'vary-prompt',
      icon: IconEdit,
      // disabled: disableModifyButtons,
      disabled: disableVaryPrompt,
      visible: !!controls?.showVaryPrompt,
      tooltip: {
        title: 'Vary Prompt',
        content: '',
        className: '',
        align: 'start',
      },
      onClick: () => {
        setShowVaryPromptDialog(true)
        setTimeout(() => {
          const textarea = varyPromptRef.current
          textarea?.focus()
          textarea?.setSelectionRange(textarea?.value.length, textarea?.value.length)
        }, 0)
      },
    },
    {
      key: 'repaint',
      icon: PaintBrush,
      disabled: disableModifyButtons || !!repaintDisableMessage || isTemplateGeneration,
      visible: !!controls?.showRepaint && !isModel2,
      tooltip: repaintDisableMessage || {
        title: 'Repaint',
        content: '',
        className: '',
        align: 'start',
      },
      onClick: handleRepaint,
    },
    {
      key: 'extend',
      icon: IconClockAdd,
      disabled: disableExtend,
      visible: !!controls?.showExtend && !isModel2,
      tooltip: extendDisableMessage || {
        title: `Extend ${creation?.settings?.duration === 2 ? 2 : 4}s`,
        content: '',
        align: 'start',
      },
      iconClassname: disableExtend ? '' : cls('text-icon-interactive'),
      onClick: disableExtend
        ? undefined
        : () => {
            setShowExtendDialog(true)
            requestAnimationFrame(() => {
              const textarea = extendPromptTextareaRef.current
              if (textarea) {
                textarea.focus()
                textarea.setSelectionRange(textarea.value.length, textarea.value.length)
              }
            })
          },
    },
    {
      key: 'upscale',
      icon: IconFeatures,
      disabled: disableUpscale,
      visible: !!controls?.showUpscale && !isModel2,
      iconClassname: disableUpscale ? '' : cls('text-icon-interactive'),
      tooltip: upscaleDisableMessage || {
        title: 'Enhance',
        content: '',
        align: 'start',
      },
      onClick: disableUpscale ? undefined : () => setShowUpscaleDialog(true),
    },
    {
      key: 'image2video',
      icon: IconMultiMedia,
      disabled: disableImage2Video,
      visible: !!controls?.showImage2Video && !isModel2,
      tooltip: { title: 'Image to Video', content: '' },
      onClick: handleImage2Video,
    },
  ]

  const rightButtons: ButtonGroupItem[] = [
    {
      key: 'favorite',
      icon: favorited ? IconHeartSolid : IconHeartOutline,
      className: '',
      disabled,
      label: String(collectsCount || ''),
      visible: !!controls?.showFavorite,
      tooltip: { content: 'Like' },
      iconClassname: favorited ? 'text-border-critical-hover' : '',
      onClick: handleFavorite,
    },
    {
      key: 'submit',
      icon: IconShareScreen,
      className: '',
      disabled,
      visible: !!controls?.showSubmit && isAuthor && isLocalDev,
      tooltip: { content: 'Submit' },
      iconClassname: '',
      onClick: handleSubmit,
    },
    {
      key: 'collection',
      icon: IconFolder,
      className: '',
      iconClassname: hasAddCollection ? 'text-icon-interactive' : '',
      disabled: isFreePlan || disabled,
      visible: !!controls?.showCollection,
      tooltip: {
        content: collectionTooltip,
        className: isFreePlan ? collectionTooltipClassname : '',
      },
      onClick: handlePickCollection,
    },
    {
      key: 'download',
      icon: DownloadIcon,
      disabled: disabled,
      // disabled: disabled || downloadSuccess || downloading,
      className: '',
      visible: !!controls?.showDownload,
      tooltip: {
        content: downloadTooltip,
        className: downloadTooltipClassname,
        closeOnClick: isBelowMd,
        delayDuration: downloadTooltip === 'Download' ? undefined : 0,
        // align: 'end'
      },
      onClick: async () => {
        if (isImage) {
          await handleImageDownload()
        }
        if (disableDefaultDownloadButton) {
          return
        }
        await innerHandleDownload(hasWatermarkFreeUrl && isAuthor)
      },
    },
    {
      key: 'comment',
      icon: IconChat,
      label: String(output?.comment_num || ''),
      disabled: disabled,
      className: '',
      tooltip: { title: 'Comment', content: '' },
      visible: !!controls?.showComment,
      onClick: handleOpenComment,
    },
    {
      key: 'share',
      icon: IconShare,
      disabled,
      visible: !!controls?.showShare,
      folded: controls?.showShare === 'fold',
      label: 'Share',
      iconOnly: true,
      tooltip: {
        content: 'Share',
      },
      onClick: () => setShowShareDialog(true),
    },
    {
      key: 'delete',
      visible: !!controls?.showDelete && ['succeed', 'failed'].includes(creation?.status ?? ''),
      folded: true,
      label: 'Delete',
      onClick: () => setShowDeleteDialog(true),
    },
    {
      key: 'visibility',
      visible: !!controls?.showVisibility,
      folded: true,
      label: 'Who can view',
      onClick: () => setShowVisibilityDialog(true),
    },
  ]

  const addToCollection = async (keys: string[]) => {
    await handleAddCollection(keys)
    selectCollectionDialogRef.current?.close()
  }

  const leftButtonGroupVisible = leftButtons.some((item) => item.visible)

  return (
    <div
      className={cls('h-8 md:h-10 w-full flex justify-end items-center', hidden ? 'invisible' : '', className)}
      aria-label='interactions'
    >
      <div
        className={cls(
          'w-full flex items-center justify-between',
          leftButtonGroupVisible ? 'justify-between' : 'justify-end',
        )}
        onClick={onClick}
      >
        <ButtonGroup
          size={size}
          items={leftButtons}
          itemClassName={cls(groupItemStyle, iconButtonParsedClassName, itemClassName)}
          iconWrapClassname={cls(
            'w-auto',
            singleColumn ? (isMobile() ? 'min-w-9 h-8' : 'min-w-10 h-10') : 'min-w-9 h-8',
          )}
          iconClassname={parsedIconClassName}
        />
        <ButtonGroup
          variant='transparent'
          size={size}
          className='gap-1'
          items={rightButtons}
          iconWrapClassname={cls(
            'w-auto',
            singleColumn ? (isMobile() ? 'min-w-8 h-8' : 'min-w-10 h-10') : 'min-w-8 h-8',
          )}
          itemClassName={cls(groupItemStyle, iconButtonParsedClassName)}
          iconClassname={parsedIconClassName}
        />
      </div>
      {/* dialogs */}
      <div className='w-0'>
        <Dialog
          open={showExtendDialog}
          className='w-100 md:w-100'
          footer={
            <div className='w-full flex'>
              <CreditSpendButton
                spu='extend/vid2vid'
                duration={nextExtendDuration || 0}
                text='Extend'
                resolution={creation?.settings?.resolution ?? null}
                version={creation?.model_version ?? ModelVersion.ONE}
                onClick={async () => {
                  await handleExtend(extendPromptTextareaRef.current?.value ?? creation?.prompt ?? '')
                  if (refreshCredit) {
                    await refreshCredit()
                  }
                  setShowExtendDialog(false)
                }}
              />
            </div>
          }
          // title='Extend the video'
          title={
            <div className='flex flex-col gap-1'>
              <span className='text-heading-lg font-bold tracking-45'>Extend Duration</span>
              <span className='text-body-md text-text-subdued tracking-15'>
                Extend your video by {nextExtendDuration} seconds.
              </span>
            </div>
          }
          onOpenChange={setShowExtendDialog}
        >
          <div className='flex flex-col gap-2 pt-6 pb-8'>
            <span className='text-body-lg tracking-32'>Prompt</span>
            <Textarea
              key={creationId}
              ref={extendPromptTextareaRef}
              className='h-30 w-full px-4 py-2'
              defaultValue={creation?.prompt}
            />
          </div>
        </Dialog>
        <Dialog
          open={showUpscaleDialog}
          title='Upscale the video'
          className='w-100 md:w-100'
          footer={
            <div className='w-full flex'>
              <CreditSpendButton
                spu='upscale/vid2vid'
                duration={realDuration ?? 0}
                resolution={creation?.settings?.resolution ?? null}
                text='Upscale'
                version={creation?.model_version ?? ModelVersion.ONE}
                onClick={async () => {
                  await handleUpscale()
                  setShowUpscaleDialog(false)
                  if (refreshCredit) {
                    await refreshCredit()
                  }
                }}
              />
            </div>
          }
          onOpenChange={setShowUpscaleDialog}
        >
          <div className='py-4'>Upscale the video to 1080p with more detail.</div>
        </Dialog>
        <Dialog
          open={showDeleteDialog}
          title={'Delete ' + (outputType === 'image' ? 'Images' : 'Video')}
          okText='Delete'
          cancelText='Cancel'
          onOk={innerHandleDelete}
          onCancel={() => {
            setShowDeleteDialog(false)
          }}
          onOpenChange={setShowDeleteDialog}
        >
          <div className='py-4'>Deleting {outputType === 'image' ? 'images' : 'video'} cannot be recovered</div>
        </Dialog>
        <Dialog
          open={showRegenerateDialog}
          title='Regenerate'
          okText='Regenerate'
          footer={
            <div className='flex gap-2 w-full pt-4'>
              <Button variant='outline' className='flex-1 rounded-md' onClick={() => setShowRegenerateDialog(false)}>
                Cancel
              </Button>
              <CreditSpendButton
                text='Regenerate'
                spu={formatSpu(creation)}
                resolution={creation?.settings?.resolution ?? null}
                duration={creation?.type === 'extend' ? extendedDuration : realDuration}
                version={creation?.model_version ?? ModelVersion.ONE}
                onClick={handleRegenerate}
              />
            </div>
          }
          onOk={handleRegenerate}
          onOpenChange={setShowRegenerateDialog}
        >
          <div className='py-4'>Regenerate with the same input?</div>
        </Dialog>
        <Dialog
          title='Vary Prompt'
          open={showVaryPromptDialog}
          footer={
            <div className='flex gap-2 w-full pt-4'>
              <Button variant='outline' className='flex-1 rounded-md' onClick={() => setShowVaryPromptDialog(false)}>
                Cancel
              </Button>
              <CreditSpendButton
                text='Create'
                spu={formatSpu(creation)}
                resolution={creation?.settings?.resolution ?? null}
                duration={creation?.type === 'extend' ? extendedDuration : realDuration}
                version={creation?.model_version ?? ModelVersion.ONE}
                onClick={handleVaryPrompt}
              />
            </div>
          }
          onClick={handleVaryPrompt}
          onOpenChange={setShowVaryPromptDialog}
        >
          <div className='py-4'>
            <div className='mb-2'>New Prompt</div>
            <Textarea ref={varyPromptRef} className='no-scrollbar' defaultValue={creation?.prompt ?? ''} />
          </div>
        </Dialog>
        <ShareDialog
          title={'Share the ' + (outputType === 'image' ? 'images' : 'video')}
          open={showShareDialog}
          link={shareLink}
          trackEventParams={trackEventParams}
          onOpenChange={setShowShareDialog}
        />
        <VisibilityDialog
          open={showVisibilityDialog}
          creationId={creationId}
          isPublic={isPublic}
          onOk={(isPublic) => {
            setIsPublic(isPublic)
            setShowVisibilityDialog(false)
          }}
          onOpenChange={setShowVisibilityDialog}
        />
        <SelectCollectionDialog
          ref={selectCollectionDialogRef}
          loading={collectionKeysLoading}
          creationId={creationId}
          selectedKeys={selectedKeys}
          onKeysChange={setSelectedKeys}
          onAddToCollection={addToCollection}
        />
      </div>
    </div>
  )
}
