import { cancelableGetVideoDuration } from '@/utils'
import { useEffect, useState } from 'react'

const url2DurationMap: Record<string, number> = {}

const useVideoDuration = (
  url: string,
  allowCache = false,
): { data: number | undefined; loading: boolean } => {
  const lastDuration = allowCache ? url2DurationMap[url] : undefined
  const shouldFetch = !lastDuration || !allowCache
  const [loading, setLoading] = useState(shouldFetch)
  const [duration, setDuration] = useState(lastDuration)

  useEffect(() => {
    const { promise, abort } = cancelableGetVideoDuration(
      shouldFetch ? url : '',
    )
    if (shouldFetch) {
      setLoading(true)
      promise
        ?.then((duration) => {
          url2DurationMap[url] = duration
          setDuration(duration)
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    }

    return () => {
      abort()
    }
  }, [shouldFetch, url])

  return {
    data: duration,
    loading,
  }
}

export const useCachedVideoDuration = (url: string) => {
  return useVideoDuration(url, true)
}
